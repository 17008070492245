import React, { useEffect, useState } from "react";
import SidebarLayout from "../components/layouts/SidebarLayout";
import PageTitle from "../components/PageTitle";
import CustomForm from "../components/CustomForm";
import { useDispatch, useSelector } from "react-redux";
import {
  clearErrors,
  clearUpdateState,
  getUserByPan,
  getUserDetails,
  updateUserDetails,
  updateUserPassword,
} from "../redux/actions/userAction";
import { toast } from "react-toastify";
import LoadingButton from "../components/LoadingButton";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import {
  FaUserEdit,
  FaExpeditedssl,
  FaRegEye,
  FaRegEyeSlash,
} from "react-icons/fa";
import { MdClose } from "react-icons/md";
import { MdAirplanemodeActive, MdAirplanemodeInactive } from "react-icons/md";
import moment from "moment";
import {
  clearDesignationErrors,
  getAllDesignation,
} from "../redux/actions/designationAction";

const UpdatePasswordModal = ({ showModal, setShowModal, user }) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const { loading, data, error } = useSelector((state) => state.updatePassword);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (data?.success) {
      toast.success(data?.message);
      dispatch(clearUpdateState());
      setPassword("");
      setConfirmPassword("");
      setShowModal(false);
      dispatch(getUserDetails(user?._id));
    }
  }, [data?.message, data?.success, dispatch, error, user?._id, setShowModal]);

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Password & confirm password must be same");
    } else {
      dispatch(updateUserPassword(user?._id, password));
    }
  };

  const handleCloseModal = () => {
    setPassword("");
    setConfirmPassword("");
    setShowModal(false);
  };
  return (
    <div
      className={`update_designation position-fixed rounded bg-dark text-light p-3 ${
        showModal ? "show_modal" : ""
      }`}
    >
      <div>
        <p className="">
          Be careful, you are changing the password for{" "}
          <span className="bg-warning text-dark p-1 me-1">
            {user?.fullName}'s
          </span>
          profile.
        </p>
        <button
          className="btn btn-danger ms-auto d-block mb-2"
          onClick={() => handleCloseModal()}
        >
          <MdClose />
        </button>
      </div>
      <CustomForm>
        <div className="form-group mb-2 position-relative">
          <label className="fw-bold text-dark" htmlFor="password">
            Password <span className="text-danger">*</span>
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id="password"
            placeholder="Rock****"
            onChange={(e) => setPassword(e.target.value)}
            name="password"
            value={password}
          />
          <div
            className="input_icon position-absolute"
            style={{ top: "30px", right: "7px", color: "grey" }}
            title={!showPassword ? "Show Password" : "Hide Password"}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
          </div>
        </div>
        <div className="form-group position-relative">
          <label className="fw-bold text-dark" htmlFor="confirmPassword">
            Confirm Password <span className="text-danger">*</span>
          </label>
          <input
            type={showPassword ? "text" : "password"}
            className="form-control"
            id="confirmPassword"
            placeholder="Rock****"
            onChange={(e) => setConfirmPassword(e.target.value)}
            name="confirmPassword"
            value={confirmPassword}
          />
          <div
            className="input_icon position-absolute"
            style={{ top: "30px", right: "7px", color: "grey" }}
            title={!showPassword ? "Show Password" : "Hide Password"}
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? <FaRegEye /> : <FaRegEyeSlash />}
          </div>
        </div>
        <button
          className="btn btn-danger mt-2 d-block mx-auto"
          disabled={!password || !confirmPassword || !user?._id || loading}
          onClick={handleUpdatePassword}
        >
          {loading ? <LoadingButton /> : "Update Password"}
        </button>
      </CustomForm>
    </div>
  );
};

const UserProfile = () => {
  const dispatch = useDispatch();
  const [editable, setEditable] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [refferalAgentName, setRefferalAgentName] = useState("");

  const { userId } = useParams();

  // GET USER DETAILS START
  const { loading, data, error } = useSelector((state) => state.getUserDetail);

  const userDetails = data?.user;

  const [userData, setUserData] = useState({
    isActive: "",
    fullName: "",
    email: "",
    phone: "",
    designation: "",
    panNumber: "",
    dob: "",
    gender: "",
    role: "",
    fatherName: "",
    village: "",
    city: "",
    state: "",
    pinCode: "",
    referredBy: "",
  });

  useEffect(() => {
    if (userId) {
      dispatch(getUserDetails(userId));
    }
  }, [userId, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch(clearErrors());
    }
    if (data?.success) {
      setUserData({
        isActive: userDetails?.isActive,
        fullName: userDetails?.fullName,
        email: userDetails?.email,
        phone: userDetails?.phone,
        designation: userDetails?.designation?._id,
        panNumber: userDetails?.panNumber,
        dob: userDetails?.dob,
        gender: userDetails?.gender,
        role: userDetails?.role,
        fatherName: userDetails?.fatherName,
        village: userDetails?.village,
        city: userDetails?.city,
        state: userDetails?.state,
        pinCode: userDetails?.pinCode,
        referredBy: userDetails?.referredBy?.panNumber,
      });
    }
  }, [dispatch, error, userDetails, data?.success]);

  // GET USER DETAILS ENDS

  // INPUT CHANGE HANDLER
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  // UPDATE USER DETAILS
  const {
    loading: updateLoading,
    data: updateData,
    error: updateError,
  } = useSelector((state) => state.updateUserDetail);

  const handleUpdateUser = () => {
    dispatch(
      updateUserDetails(
        userId,
        userData?.isActive,
        userData?.fullName,
        userData?.email,
        userData?.phone,
        userData?.designation,
        userData?.panNumber,
        userData?.dob,
        userData?.gender,
        userData?.role,
        userData?.fatherName,
        userData?.village,
        userData?.city,
        userData?.state,
        userData?.pinCode,
        userData?.referredBy
      )
    );
  };

  useEffect(() => {
    if (updateError) {
      toast.error(updateError);
      dispatch(clearErrors());
    }
    if (updateData?.success) {
      toast.success(updateData?.message);
      setEditable(false);
      dispatch(clearUpdateState());
      dispatch(getUserDetails(userId));
    }
  }, [dispatch, updateData?.message, updateData?.success, updateError, userId]);

  const handleChnageUserState = () => {
    dispatch(
      updateUserDetails(
        userId,
        !userDetails?.isActive,
        userData?.fullName,
        userData?.email,
        userData?.phone,
        userData?.designation,
        userData?.panNumber,
        userData?.dob,
        userData?.gender,
        userData?.role,
        userData?.fatherName,
        userData?.village,
        userData?.city,
        userData?.state,
        userData?.pinCode,
        userData?.referredBy
      )
    );
  };

  // GET ALL DESIGNATION START
  const {
    loading: allDesignationLoading,
    data: allDesignationData,
    error: allDesignationError,
  } = useSelector((state) => state.getAllDesignations);

  const designationList = allDesignationData?.designations;

  useEffect(() => {
    dispatch(getAllDesignation());
  }, [dispatch]);

  useEffect(() => {
    if (allDesignationError) {
      dispatch(clearDesignationErrors());
    }
  }, [allDesignationError, dispatch]);

  // GET ALL DESIGNATION ENDS

  // GET USER BY PAN NUMBER START
  const {
    data: agentData,
    loading: agentLoading,
    error: agentError,
  } = useSelector((state) => state.getUserByPanNumber);

  useEffect(() => {
    if (userData?.referredBy?.length === 10)
      dispatch(getUserByPan(userData?.referredBy));
  }, [dispatch, userData?.referredBy?.length, userData?.referredBy]);

  useEffect(() => {
    if (agentError) {
      toast.error(agentError);
      dispatch(clearErrors());
    }
    if (agentData?.success) {
      setRefferalAgentName(agentData?.user?.fullName);
    }
    if (userData?.referredBy?.length !== 10) {
      setRefferalAgentName("");
    }
  }, [
    agentError,
    dispatch,
    agentData?.success,
    agentData?.user?.fullName,
    userData?.referredBy?.length,
  ]);
  // GET USER BY PAN NUMBER ENDS

  return loading ? (
    <Loader />
  ) : (
    <div className="registration_main">
      <SidebarLayout>
        <div className="registration_content">
          <PageTitle title={`${userDetails?.fullName}'s Details`} />

          <div className="mb-4">
            <CustomForm>
              <div className="row g-3">
                <div className="col-12">
                  <h5>
                    Currently, user is{" "}
                    <span
                      className={
                        userDetails?.isActive ? "text-success" : "text-danger"
                      }
                    >
                      {userDetails?.isActive ? "Activated" : "Disabled"}
                    </span>
                    , If you want to change the status (activate/disable) the
                    user click on the below button____
                  </h5>
                  <button
                    className={`btn btn-${
                      userDetails?.isActive ? "success" : "info"
                    } mb-3 mx-auto d-flex align-items-center`}
                    onClick={handleChnageUserState}
                  >
                    {updateLoading ? (
                      <LoadingButton />
                    ) : (
                      <>
                        {userDetails?.isActive ? (
                          <MdAirplanemodeInactive className="me-1 fs-3" />
                        ) : (
                          <MdAirplanemodeActive className="me-1 fs-3" />
                        )}
                        {userDetails?.isActive ? "In-activate" : "Activate"}
                      </>
                    )}
                  </button>
                </div>
              </div>
            </CustomForm>
          </div>

          <div className="mb-4 pb-2 d-flex align-items-center justify-content-between border-bottom">
            <h4>Update User Details:</h4>
            <div className="ms-auto d-flex">
              <button
                className={`btn btn-${
                  editable ? "danger" : "warning"
                } me-1 d-flex align-items-center`}
                onClick={() => setEditable(!editable)}
              >
                {editable ? (
                  <FaExpeditedssl className="me-1 fs-4" />
                ) : (
                  <FaUserEdit className="me-1 fs-4" />
                )}
                Edit
              </button>
              <button
                className="btn btn-danger d-flex align-items-center"
                onClick={() => setShowModal(true)}
              >
                Change Password
              </button>
            </div>
          </div>
          <div className="registration_data">
            <CustomForm>
              <div className={`row g-3 ${editable ? "inputEditBorder" : ""}`}>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="registerAs">
                      Role <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="registerAs"
                      onChange={handleInputChange}
                      name="role"
                      value={editable ? userData?.role : userDetails?.role}
                      readOnly={editable ? false : true}
                      disabled={editable ? false : true}
                    >
                      <option>Select One Option</option>
                      <option value={12}>Admin</option>
                      <option value={11}>Associate</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="fullName">
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullName"
                      placeholder="Ex: Rahul Singh"
                      onChange={handleInputChange}
                      name="fullName"
                      value={
                        editable ? userData?.fullName : userDetails?.fullName
                      }
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="fatherName">
                      Father Name <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fatherName"
                      placeholder="Father name of applicant"
                      onChange={handleInputChange}
                      name="fatherName"
                      value={
                        editable
                          ? userData?.fatherName
                          : userDetails?.fatherName
                      }
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="panNumber">
                      PAN Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="panNumber"
                      placeholder="Ex: CSQLD1234R"
                      value={
                        editable ? userData?.panNumber : userDetails?.panNumber
                      }
                      name="panNumber"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="emailId">
                      Email ID <span className="text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="emailId"
                      placeholder="Ex: example@test.com"
                      value={editable ? userData?.email : userDetails?.email}
                      name="email"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="phone">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="phone"
                      placeholder="Ex: 9876543210"
                      value={editable ? userData?.phone : userDetails?.phone}
                      name="phone"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="dob">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      id="dob"
                      placeholder="Ex: 26-11-2000"
                      value={
                        editable
                          ? userData?.dob
                          : moment(userDetails?.dob).format("DD-MM-YYYY")
                      }
                      name="dob"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="designation">
                      Designation
                    </label>
                    <select
                      className="form-select"
                      id="designation"
                      onChange={handleInputChange}
                      name="designation"
                      value={
                        editable
                          ? userData?.designation
                          : userDetails?.designation?._id
                      }
                      readOnly={editable ? false : true}
                      disabled={editable ? false : true}
                    >
                      <option>Select One Option</option>
                      {designationList?.length &&
                        designationList?.map((item) => {
                          return (
                            <option value={item?._id}>{item?.title}</option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="gender">
                      Gender <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-select"
                      id="gender"
                      onChange={handleInputChange}
                      name="gender"
                      value={editable ? userData?.gender : userDetails?.gender}
                      readOnly={editable ? false : true}
                      disabled={editable ? false : true}
                    >
                      <option>Select One Option</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="locality">
                      Locality/Village <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="locality"
                      placeholder="Ex: Locality/Village"
                      value={
                        editable ? userData?.village : userDetails?.village
                      }
                      name="village"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="City">
                      City <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="City"
                      placeholder="Ex: Rewari"
                      value={editable ? userData?.city : userDetails?.city}
                      name="city"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="state">
                      State <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="state"
                      placeholder="Ex: Haryana"
                      value={editable ? userData?.state : userDetails?.state}
                      name="state"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="pinCode">
                      PIN Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="pinCode"
                      placeholder="Ex: 123456"
                      value={
                        editable ? userData?.pinCode : userDetails?.pinCode
                      }
                      name="pinCode"
                      onChange={handleInputChange}
                      readOnly={editable ? false : true}
                    />
                  </div>
                </div>
                <div className="col-md-6"></div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="referredBy">
                      refferred By (refferral Code){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="referredBy"
                      placeholder="Ex: ABCDE1234A"
                      value={
                        editable
                          ? userData?.referredBy
                          : userDetails?.referredBy?.panNumber
                      }
                      name="referredBy"
                      readOnly={editable ? false : true}
                      disabled={editable ? false : true}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="fw-bold" htmlFor="refferralUserName">
                      Refferal User's Name (Auto fetch by refferral ID){" "}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      style={{ color: "red" }}
                      type="text"
                      className="form-control"
                      id="refferralUserName"
                      readOnly
                      disabled
                      value={refferalAgentName}
                      placeholder="Refferal User's Name (Auto fetch by refferral ID)"
                    />
                  </div>
                </div>
              </div>
              {editable && (
                <button
                  onClick={handleUpdateUser}
                  className="btn btn-danger d-block mx-auto mt-3 text-uppercase"
                >
                  {updateLoading ? <LoadingButton /> : "Update"}
                </button>
              )}
            </CustomForm>
          </div>
        </div>
        <UpdatePasswordModal
          showModal={showModal}
          setShowModal={setShowModal}
          user={userDetails}
        />
      </SidebarLayout>
    </div>
  );
};

export default UserProfile;
